import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Plugins } from '@capacitor/core';

const { Storage } = Plugins;

@Injectable()
export class GlobalService {

    private httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

    public castillos: string;
    public collas: string;
    public portada: string;
    public streamingVideo: string;
    public tablaPuntos: string;
    public live: string;
    public normativa: string;
    public noticias: string;
    public patrocinadores: string;
    public rankingConcurso: string;
    public rankingDetalle: string;
    public rankingTemporada: string;

    constructor(private http: HttpClient) { 
        this.getMainService().subscribe((data) => {
            console.log(data);
            this.castillos = data.endpoints.datos.castillos;
            this.collas = data.endpoints.datos.collas;
            this.portada = data.endpoints.datos.portada;
            this.streamingVideo = data.endpoints.datos.streaming_video;
            this.tablaPuntos = data.endpoints.datos.tabla_de_puntos;
            this.live = data.endpoints.menu.live;
            this.normativa = data.endpoints.menu.normativa;
            this.noticias = data.endpoints.menu.noticias;
            this.patrocinadores = data.endpoints.menu.patrocinadores;
            this.rankingConcurso = data.endpoints.menu.ranquing.concurso;
            this.rankingDetalle = data.endpoints.menu.ranquing.detalle;
            this.rankingTemporada = data.endpoints.menu.ranquing.temporada;
        });
    }

    getUltimasNoticias(): any {
        console.log(this.noticias);
        return this.http.get(this.noticias).pipe(
            map(response => response as any[])
        );
    }
    getSplashScreen(): any {
        return this.http.get("https://www.concursdecastells.cat/pwa/webservice.php?accion=entrada").pipe(
            map(response => response as any[])
        );
    }
    getCastells(): any {
        return this.http.get(this.castillos).pipe(
            map(response => response as any[])
        );
    }
    getRankingGeneral(): any {
        return this.http.get(this.rankingConcurso).pipe(
            map(response => response as any[])
        );
    }
    getRankingSeason(): any{
        return this.http.get(this.rankingTemporada).pipe(
            map(response => response as any[])
        );
    }
    getRankingDetail(tipo, codigo): any {
        return this.http.get("https://www.concursdecastells.cat/pwa/webservice.php?accion=rankingdetalledavid&tipo=" + tipo + "&codigo=" + codigo + "").pipe(
            map(response => response as any[])
        );
    }
    getDatosCalculadora(): any{
        return this.http.get(this.castillos).pipe(
            map(response => response as any[])
        );
    }
    getRatings(): any {
        return this.http.get(this.tablaPuntos).pipe(
            map(response => response as any[])
        );
    }
    getTerms(): any {
        return this.http.get(this.normativa).pipe(
            map(response => response as any[])
        );
    }
    getCollas(): any {
        return this.http.get(this.collas).pipe(
            map(response => response as any[])
        );
    }
    getLive(): any {
        return this.http.get(this.live).pipe(
            map(response => response as any[])
        );
    }
    getTwitter(): any {
        return this.http.get("https://www.concursdecastells.cat/apps/twitter/data/twitter.json").pipe(
            map(response => response as any[])
        );
    }
    getFacebook(): any {
        return this.http.get("https://www.concursdecastells.cat/pwa/2014/castells_facebook_2014.php").pipe(
            map(response => response as any[])
        );
    }
    getStreamingVide(): any {
        return this.http.get(this.streamingVideo).pipe(
            map(response => response as any[])
        );
    }
    getSponsor(): any {
        return this.http.get(this.patrocinadores).pipe(
            map(response => response as any[])
        );
    }
    getMainService(): any {
        return this.http.get("https://www.concursdecastells.cat/pwa/config-pwa.php").pipe(
            map(response => response as any[])
        );
    }
    async setString(key: string, value: string) {
        await Storage.set({ key, value });
    }

    async getString(key: string): Promise<{ value: any }> {
        return (await Storage.get({ key }));
    }

    async setObject(key: string, value: any) {
        await Storage.set({ key, value: JSON.stringify(value) });
    }

    async getObject(key: string): Promise<{ value: any }> {
        const ret = await Storage.get({ key });
        var res = JSON.parse(ret.value);
        res['actual'] = key;
        return res;
    }


    async removeItem(key: string) {
        await Storage.remove({ key });
    }

    async clear() {
        await Storage.clear();
    }
}
