import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { MenuController, Platform, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Storage } from '@ionic/storage';
import { GlobalService } from '../app/service/global.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  
  appPages = [
    {
      title: 'Home',
      url: '/home',
      icon: 'calendar'
    },
    {
      title: 'Home',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'Map',
      url: '/app/tabs/map',
      icon: 'map'
    }
  ];
  loggedIn = false;
  dark = false;
  public imagen: string;
  public tiempo: number;
  constructor(
    private menu: MenuController,
    private platform: Platform,
    private router: Router,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private storage: Storage,
    private swUpdate: SwUpdate,
    private toastCtrl: ToastController,
    private globalService: GlobalService
  ) {
    this.globalService.getSplashScreen().subscribe((data) => {
      document.getElementById("imagen-src").setAttribute('src', data.imagen);
      this.globalService.getMainService().subscribe((datos) => {
        this.tiempo = datos.parametros.tiempo_espera_portada;
        this.handleSplashScreen();
      });
    });
  }
  async handleSplashScreen(): Promise<void> {
    try {
      await this.platform.ready();
    } catch (error) {
      console.error('Platform initialization bug')
    }
    const splash = document.getElementById('splash-screen');
    splash.style.opacity = '0';
    var t = this.tiempo * 1000;
    setTimeout(() => { splash.remove() }, t);
  }

  async ngOnInit() {
  }

}
